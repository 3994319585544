<template>
    <div class="card">
        <div class="header">
            <a-icon class="back" @click="$router.go(-1)" type="left" />
            <span class="title">BCI Card</span>
        </div>
        <div class="top">
            <div class="card-image-box">
                <img class="card-image" src="@/assets/imgs/card/card.png" alt="">
            </div>
        </div>
        <div class="flex btns">
            <div class="flex column btn-balance">
                <img class="btn-icon" src="@/assets/imgs/card/balance_icon.png" alt="">
                <span>Balance</span>
            </div>
            <div class="sep"></div>
            <div class="flex column btn-transfer">
                <img class="btn-icon" src="@/assets/imgs/card/transfer_icon.png" alt="">
                <span>Transfer</span>
            </div>
        </div>
        <div class="navs">
            <a-row type="flex" :gutter="15" justify="space-between">
                <a-col class="nav-item" :span="6" v-for="nav,i in navs" :key="'nav'+i" @click="goNav(nav.url)">
                    <img class="nav-img" :src="nav.icon" alt="">
                    <span>{{ nav.title }}</span>
                </a-col>
            </a-row>
        </div>
        <div class="news">
            <div class="news-item" v-for="n,i in news" :key="'news'+i">
                <div class="mask">
                    <img class="news-bg" :src="n.image" alt="">
                    <div class="title">{{ n.title }}</div>
                    <div class="news-content">
                        <div class="news-title">{{ n.newsTitle }}</div>
                        <div class="subtitle">{{ n.subtitle }}</div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Card",
    data() {
        return {
            address: '',
            navs: [
                { icon: require('@/assets/imgs/card/bci_icon.png'), title: 'Login', url: '' },
                { icon: require('@/assets/imgs/card/invest_icon.png'), title: 'Invest', url: '' },
                { icon: require('@/assets/imgs/card/pay_icon.png'), title: 'Pay', url: '' },
                { icon: require('@/assets/imgs/card/insurance_icon.png'), title: 'Insurance', url: 'insurance' },
                { icon: require('@/assets/imgs/card/report_icon.png'), title: 'Report', url: '' },
                { icon: require('@/assets/imgs/card/nft_icon.png'), title: 'Nft', url: '' },
                { icon: require('@/assets/imgs/card/mortgage_icon.png'), title: 'Mortgage', url: '' },
                { icon: require('@/assets/imgs/card/more_icon.png'), title: 'More', url: '' },
            ],
            news: [
                {
                    title: 'News',
                    newsTitle: 'Mint your Own NFTs',
                    subtitle: 'Select an image from your camera roll set a name and description,and selecta blockchainNFTGOwill mint your image as an NFT on the blockchain of your choice,and send it to either a wallet address you provide,or your bullt in NFT GO wallet',
                    image: require('@/assets/imgs/card/news.png')
                }
            ]
        };
    },
    components: {},
    mounted() {
        
    },
    methods: {
        goNav(url){
            if(url){
                this.$router.push({name: url});
            }
        }
    },
};
</script>
<style lang="less" scoped>
.card {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    .header{
        background: transparent;
    }
    .top{
        width: 100%;
        height: 220px;
        background: url("~@/assets/imgs/card/topbg.png") no-repeat;
        background-size: cover;
        background-position: bottom center;
        position: relative;
        .card-image-box {
            position: absolute;
            bottom: -50px;
            width: 100%;
            .card-image {
                height: 200px;
            }
        }
    }
    .btns {
        margin-top: 80px;
        align-items: flex-start;
        border-bottom: 2px solid #f0efef;
        .flex{
            flex: 1;
            span{
                padding: 7px;
            }
        }
        .btn-icon {
            height: 35px;
        }
        .sep{
            height: 50px;
            width: 1px;
            background: #bcbcbc;
        }
    }
    .navs {
        padding-top: 20px;
        .nav-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            .nav-img {
                width: 30px;
                padding-bottom: 3px;
            }
        }
    }
    .news{
        padding: 20px;
        .news-item {
            position: relative;
            height: 220px;
            color: #fff;
            .mask {
                position: absolute;
                width: 100%; height: 100%;
                top: 0; left: 0;
                z-index: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                text-align: left;
                padding: 10px;
                .news-bg{
                    position: absolute;
                    width: 100%; height: 100%;
                    top: 0; left: 0;
                    z-index: -1;
                }
                .title {
                    font-weight: bold;
                    font-size: 1.1rem;
                }
                .news-title {
                    font-weight: bold;
                }
                .subtitle {
                    font-size: 0.5rem;
                }
            }
        }
    }
}
</style>
